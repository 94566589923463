
export default {
  churchInfo: null,  // 教堂信息
  menuInfo: [], // 菜单信息
  comId: '1675333552686756015',  // 教堂标识id
  detail: {      // 详情信息
    menu: null,   //  当前的菜单数据
    data: null,    // 当前的详情数据
  },
  liveInfo: null,  // 直播信息
}