/**
 * 深拷贝
 * @param obj
 * @param hash
 */
export function deepClone(obj: any, hash = new WeakMap()) {
  if (obj === null) return obj; // 如果是null或者undefined我就不进行拷贝操作
  if (obj instanceof Date) return new Date(obj);
  if (obj instanceof RegExp) return new RegExp(obj);
  // 可能是对象或者普通的值  如果是函数的话是不需要深拷贝
  if (typeof obj !== "object") return obj;
  // 是对象的话就要进行深拷贝
  if (hash.get(obj)) return hash.get(obj);
  let cloneObj = new obj.constructor();
  // 找到的是所属类原型上的constructor,而原型上的 constructor指向的是当前类本身
  hash.set(obj, cloneObj);
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // 实现一个递归拷贝
      cloneObj[key] = deepClone(obj[key], hash);
    }
  }
  return cloneObj;
}

/**
 * 统一处理菜单格式
 * @param menuList
 */
export const getChildrenMenu: any = (menuList: any[]) => {
  if(!menuList?.length) {
    return;
  }

  return menuList?.map((menu: any) => {
    return {
      label: menu.moduleName,
      key: menu.id + '',
      children: getChildrenMenu(menu?.designList || []),
    }
  })
};

/**
 * 递归获取菜单中特定的名称的项
 * @param menus
 * @param names
 */
export const getSomeMenu = (menus: any[], names: string[]) => {
  let result: any = [];
  menus?.forEach((menu: any) => {
    if(names?.includes(menu.moduleName)) {
      result.push(menu);
    }
    if(menu?.designList?.length) {
      const data: any = getSomeMenu(menu.designList, names);
      result = [...result, ...data];
    }
  })
  return result;
}

/**
 * 获取url参数
 * @param url
 */
export const getQueryParams = (url: string) => {
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params: any = {};
  paramArr.map((param) => {
    const [key, val] = param.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
};

/**
 * 提取html字符中的文本
 * @param htmlString
 */
export function extractTextFromHTML(htmlString: string) {
  if(!htmlString) {
    return '';
  }
  // 使用正则表达式匹配文本内容
  const regex = /<[^>]+>/g;
  let text = htmlString.replace(regex, '').replace(/\n/g, '').replace(/&\w*;/g, '');
  return text;
}