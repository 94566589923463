import axios, { AxiosRequestConfig } from 'axios';

const instance = axios.create({
  baseURL: 'https://hzjh.hangzhouchurch.com',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // const token = localStorage.getItem("access_token");
  // if (token) {
  //   config.headers.Authorization = "Bearer " + token;
  // }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  const { data } = response;

  switch (data?.code) {
    case 200:
      return data?.data;
    case 401:
    case 500:
      return Promise.reject(data);
    default:
      return Promise.reject(data);
  }
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error);
});


export const request = (method: 'post' | 'get', url: string) => {
  return (params?: any, config?: AxiosRequestConfig) => {
    // 请求参数
    const data: any = {};
    // if(method === 'post') {
    //   data.data = params;
    // } else {
    //   data.params = params;
    // }
    data.params = params;

    return instance.request({
      method,
      url,
      ...config,
      ...data,
    });
  }
};