import React from 'react';

export function useContainerScroll(container: React.RefObject<HTMLElement>) {
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  React.useEffect(() => {
    if (container.current) {
      const ro = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { height, width } = entry.contentRect;
          setHeight(height);
          setWidth(width);
        }
      });
      ro.observe(container.current);
      return () => {
        ro.disconnect();
      };
    }
  }, [container.current]);

  return { height, width };
}