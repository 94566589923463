export const UPDATE_CHURCH = 'UPDATE_CHURCH';
export const UPDATE_MENU = 'UPDATE_MENU';
export const UPDATE_CURRENT_MENU = 'UPDATE_CURRENT_MENU';
export const UPDATE_DETAIL = 'UPDATE_DETAIL';
export const UPDATE_HISTORY = 'UPDATE_HISTORY';
export const WINDOW_WIDTH = 'WINDOW_WIDTH';
export const UPDATE_LIVE_INFO = 'UPDATE_LIVE_INFO';

export const updateChurchInfo = (data: any) => {
  return {
    type: UPDATE_CHURCH,
    data,
  }
};

export const updateLiveInfo = (data: any) => {
  return {
    type: UPDATE_LIVE_INFO,
    data,
  }
};

export const updateMenu = (menu: any[]) => {
  return {
    type: UPDATE_MENU,
    menu,
  }
};

// 更新当前菜单信息
export const updateCurrentMenu = (menu: any) => {
  return {
    type: UPDATE_CURRENT_MENU,
    menu,
  }
};

// 更新详情信息
export const updateDetail = (data: any) => {
  return {
    type: UPDATE_DETAIL,
    data,
  }
};

// 更新主日详情信息
export const updateHistory = (data: any) => {
  return {
    type: UPDATE_HISTORY,
    data,
  }
};

// 更新视窗宽度
export const updateWindowWidth = (width: number) => {
  return {
    type: WINDOW_WIDTH,
    width,
  }
};