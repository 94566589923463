import { request } from '@/utils/request';

// 查询教堂信息
export const getChurchInfo = request('get', '/zhjt/api/npc/getCompany');
// 查询菜单
export const getMenuInfo = request('get', '/zhjt/api/npc/InformationDesignCompanyTitle');
// 获取首页信息
export const getHomeInfo = request('get', '/zhjt/api/npc/InformationDesignCompany');
// 查询菜单详情页面信息
export const getPageInfo = request('get', '/zhjt/api/npc/listNpcInformationCompany');
// 通过id查询菜单信息
export const getMenuById = request('get', '/zhjt/api/npc/InformationDesignById');
// 查询主日信息
export const getHistoryList = request('get', '/api/live/newlistHistoryLiveStreams');
// 通过直播id获取直播信息
export const getLiveInfo = request('get', '/api/live/getById');