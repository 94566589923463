import React, { useState, useEffect, useRef } from 'react';
import {Menu, message, Spin, Popover} from "antd";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {getChurchInfo, getMenuInfo} from '@/service/home';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { updateChurchInfo, updateMenu, updateWindowWidth } from "@/redux/actions";
import { getChildrenMenu } from '@/utils/tools';
import { useContainerScroll } from '@/hooks/use-container-scroll';
import Style from './index.module.less';

const Layout = (props: any) => {
  const navigate = useNavigate();
  const { dispatch, comId } = props;
  const containerRef = useRef() as React.RefObject<HTMLDivElement>;
  const [churchInfo, setChurchInfo] = useState<any>(null);
  const [menuInfo, setMenuInfo] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showMinMenu, setShowMinMenu] = useState(false);

  // 表格容器信息
  const { width } = useContainerScroll(containerRef);

  const gotoHome = () => {
    navigate('/home');
  };

  useEffect(() => {
    if(width >= 1024) {
      setShowMinMenu(false);
    }
    dispatch(updateWindowWidth(width));
  }, [width]);

  // 获取教堂信息
  const fetchChurchInfo = () => {
    setLoading(true);
    getChurchInfo({
      comId,
    }).then((res) => {
      setLoading(false);
      setChurchInfo(res);
      dispatch(updateChurchInfo(res));
    }).catch((err) => {
      setLoading(false);
      message.error(err?.message || err?.msg || '网络异常');
    })
  };

  // 获取菜单信息
  const fetchMenuInfo = () => {
    setLoading(true);
    getMenuInfo({
      comId,
    }).then((res: any) => {
      setLoading(false);
      let menus = getChildrenMenu(res) || [];
      const ministry = menus?.find((item: any) => item?.key === '1212');
      menus = menus?.filter((item: any) => item.key !== '1212' && item.key !== '1245');
      // 获取聚会信息
      const info = menus?.find((item: any) => item.key === '1258');
      const others = menus.filter((item: any) => item.key !== '1258');

      others?.forEach((item: any) => {
        if(item.key === '1672' && item.children) {
          item.children = [
            ...item.children,
            { label: '教会事工', key: `work_${ministry?.key}`},
            { label: '教会团契', key: `group_${ministry?.key}`},
            info,
          ]
        }
      })
      others?.splice(1, 0, {label: '主日礼拜', key: '/history'});
      setMenuInfo([
        {label: '首页', key: '/home'},
        ...others,
      ]);
      dispatch(updateMenu(res));
    }).catch((err) => {
      setLoading(false);
      message.error(err?.message || err?.msg || '网络异常');
    })
  };

  useEffect(() => {
    fetchChurchInfo();
    fetchMenuInfo();
  }, []);

  // 菜单点击
  const handleClick = (menu: any) => {
    const p = /^[A-Za-z]+_\d+$/g;
    if(menu?.key?.indexOf('/') > -1) {
      navigate(menu.key);
    } else if (p.test(menu.key)) {
      const info = menu.key?.split('_');
      navigate(`/list-info?moduleId=${info[1]}&type=${info[0]}`);
    } else {
      navigate(`/list-info?moduleId=${menu?.key}`);
    }

    //处理移动特殊情况
    setShowMinMenu(false);
  };

  // 获取当前周年
  const getYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const titleYear = year - 2023 + 74;
    return titleYear;
  };

  const menuFoot = (
    <div className={Style.footMenu}>
      <div className={Style.footTitle}>欢迎加入</div>
      <div className={Style.joinIcons}>
        <Popover
          title={null}
          content={(
            <div
              className={Style.popContent}
              style={width < 1024 ? {height: 130, width: 130} : {}}>
              <img className={Style.popImg} src="https://db.jesusl.cn/record/1675333552686756015/a216d7d7-a33e-4649-8249-ba05f0777ffe.jpg"/>
            </div>
          )}
        >
          <img
            className={Style.joinImg}
            src="https://db.jesusl.cn/record/1675333552686756015/0f504995-a4ab-4b36-84f1-a36c225ed950.png"
          />
        </Popover>
        <Popover
          title={null}
          content={(
            <div className={Style.popContent} style={width < 1024 ? {height: 130, width: 130} : {}}>
              <img className={Style.popImg} src="https://db.jesusl.cn/record/1675333552686756015/4b09b0e7-7d58-4751-90e3-72018076413f.png"/>
            </div>
          )}
        >
          <img
            className={Style.joinImg}
            src="https://db.jesusl.cn/record/1675333552686756015/9645cd41-8dbe-4c36-8a79-6445d18ae6b0.png"
          />
        </Popover>
        <Popover
          title={null}
          content={(
            <div className={Style.popContent} style={width < 1024 ? {height: 130, width: 130} : {}}>
              <img className={Style.popImg} src="https://db.jesusl.cn/record/1675333552686756015/ecbf9525-9c94-43a7-8985-37a615e6149a.png"/>
            </div>
          )}
        >
          <img
            className={Style.joinImg}
            src="https://db.jesusl.cn/record/1675333552686756015/8876f7b7-91b8-439f-bb5b-7a9bdf74b133.png"
          />
        </Popover>
        <Popover
          title={null}
          content={(
            <div className={Style.popContentHeight} style={width < 1024 ? {height: 150, width: 130} : {}}>
              <img className={Style.popImg} src="https://db.jesusl.cn/record/1675333552686756015/e9a89958-52a0-4d96-b6cb-33f9616dca19.jpg"/>
            </div>
          )}
        >
          <img
            className={Style.joinImg}
            src="https://db.jesusl.cn/record/1675333552686756015/589bdb64-c0d5-46ad-8320-bea6a234c163.png"
          />
        </Popover>
      </div>
    </div>
  );

  return (
    <Spin wrapperClassName={Style.spin} spinning={loading}>
      <div className={Style.home} ref={containerRef}>
        {
          width < 1024 ? (
            <div className={Style.minTop}>
              <img
                style={{ height: 40, cursor: 'pointer' }}
                onClick={() => navigate('/home')}
                src="https://db.jesusl.cn/record/1675333552686756015/6e60fcb1-38f1-44e4-83e7-bda3579e49d7.png"
              />
              {
                showMinMenu ? (
                  <CloseOutlined onClick={() => setShowMinMenu(false)} className={Style.minIcon} />
                ) : (
                  <MenuOutlined onClick={() => setShowMinMenu(true)} className={Style.minIcon}/>
                )
              }
            </div>
          ) : null
        }
        {
          showMinMenu ? (
            <div className={Style.minMenu}>
              <Menu
                className={Style.menuList}
                mode="inline"
                onClick={handleClick}
                items={menuInfo}
              />
              {menuFoot}
            </div>
          ) : null
        }
        <div
          className={Style.head}
          style={
            width < 1024
            ? {
              fontSize: '1.5vw',
              height: 50,
              padding: '0 4vw',
            }
            : {}}>
          <div className={Style.headLeft}>
            <span onClick={gotoHome} className={Style.title}>{churchInfo?.fullName}</span>
            <div className={Style.tag}>
              <span className={Style.tagText}>
                {
                  width > 1024 ? `Centennial Church ${getYear()}th` : `基督教百年堂 ${getYear()}th`
                }
              </span>
            </div>
          </div>
          <div className={Style.headRight} style={width < 1024 ? { fontSize: '1.5vw' } : {}}>
            <div className={Style.linkOne} onClick={() => navigate('/join-serve')}>
              <img className={Style.icon} src="https://db.jesusl.cn/record/1675333552686756015/5d1989cd-2422-4f5f-9484-4f699b462d8c.png"/>
              <span>参与服侍</span>
            </div>
            <div className={Style.linkOne} onClick={() => navigate('/contact-us')}>
              <img
                className={Style.icon}
                src="https://db.jesusl.cn/record/1675333552686756015/517e2a8a-3336-4b76-8963-481f4e3fec5f.png"/>
              <span>联系我们</span>
            </div>
            <div className={Style.linkOne} onClick={() => navigate('/prayer')}>
              <img
                className={Style.icon}
                src="https://db.jesusl.cn/record/1675333552686756015/a0449056-cee0-4348-b2b3-6c640b284f06.png"/>
              <span>爱心代祷</span>
            </div>
          </div>
        </div>
        <div className={Style.body}>
          {
            width < 1024 ? null : (
              <div className={Style.bodyLeft}>
                <div className={Style.leftContainer}>
                  <img className={Style.log} src="https://db.jesusl.cn/record/1675333552686756015/5877d4b5-81f3-43dc-8e14-24575a2d95c7.png"/>
                  <Menu
                    className={Style.menuList}
                    mode="vertical"
                    theme="dark"
                    onClick={handleClick}
                    items={menuInfo}
                  />
                  {menuFoot}
                </div>
                <div className={Style.rightContainer}>

                </div>
              </div>
            )
          }
          <div className={Style.bodyRight}>
            <div className={Style.contentBody}>
              {props.children}
            </div>
            <div className={Style.footInfo}>
              <div className={width < 1024 ? `${Style.minInfo} ${Style.info}` : Style.info}>
                <div className={Style.itemContainer}>
                  <div className={Style.title}>主日聚会</div>
                  <div className={Style.extraLabel}>主日晨祷 06:30-07:00</div>
                  <div className={Style.extraList}>
                    <div className={Style.extraText}>第 一 堂 07:00~08:40</div>
                    <div className={Style.extraText}>第 二 堂 09:20~11:00 </div>
                    <div className={Style.extraText}>第 三 堂 19:00~20:40</div>
                  </div>
                </div>
                <div className={Style.itemContainer}>
                  <div className={Style.title}>晨祷会</div>
                  <div className={Style.extraLabel}>周一至周六早晨 05:30-06:30 06:30-07:00</div>
                  <div className={Style.title}>晚祷会</div>
                  <div className={Style.extraLabel}>周四晚上 19:00-20:30</div>
                </div>
                <div className={Style.itemContainer}>
                  <div className={Style.title}>青年聚会</div>
                  <div className={Style.extraLabel}>周二晚上 19:00-21:00</div>
                </div>
                <div className={Style.itemContainer}>
                  <div className={Style.extraLabel}>我们的地址:</div>
                  <div className={Style.title}>{churchInfo?.address}</div>
                  <div className={Style.extraLabel}>致电我们:</div>
                  <div className={Style.title}>(0574) 87313084</div>
                </div>
              </div>
              <div className={width < 1024 ? `${Style.netCode} ${Style.minNetCode}` : Style.netCode}>
                <p className={Style.netText}>© 2020 宁波市基督教百年堂. All Rights Reserved.</p>
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" className={Style.netText}>
                  {churchInfo?.icp} {churchInfo?.gaicp} {churchInfo?.netcode}
                </a>
                <p className={Style.netText}>友情链接： 宁波国际堂 | 宁波圣教堂 | 宁波望春堂</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  }
};
const ChurchLayout: any = connect(mapStateToProps)(Layout);

export default ChurchLayout;