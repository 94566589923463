import { combineReducers } from 'redux';
import {
  UPDATE_CHURCH,
  UPDATE_MENU,
  UPDATE_CURRENT_MENU,
  UPDATE_DETAIL,
  UPDATE_HISTORY,
  WINDOW_WIDTH,
  UPDATE_LIVE_INFO,
} from '../actions';

const churchInfo = (state = {}, action: any) => {
  if(action.type === UPDATE_CHURCH) {
    return {
      ...state,
      ...action.data,
    }
  }
  return state;
};

const liveInfo = (state = {}, action: any) => {
  if(action.type === UPDATE_LIVE_INFO) {
    return {
      ...state,
      ...action.data,
    }
  }
  return state;
};

const menuInfo = (state = [], action: any) => {
  if(action.type === UPDATE_MENU) {
    return [
      ...state,
      ...action.menu,
    ]
  }
  return state;
};

const comId = (state = '', action: any) => {
  return state;
};

const detail = (state = {}, action: any) => {
  if(action.type === UPDATE_CURRENT_MENU) {
    return {
      ...state,
      menu: action.menu,
    }
  }
  if(action.type === UPDATE_DETAIL) {
    return {
      ...state,
      data: action.data,
    }
  }
  return state;
};

const historyDetail = (state = {}, action: any) => {
  if(action.type === UPDATE_HISTORY) {
    return {
      ...state,
      ...action.data,
    }
  }
  return state;
};

const windowWidth = (state = 0, action: any) => {
  if(action.type == WINDOW_WIDTH) {
    return action.width;
  }
  return state;
};

const church = combineReducers({
  churchInfo,
  menuInfo,
  comId,
  detail,
  historyDetail,
  windowWidth,
  liveInfo,
});

export default church;