import React, { Suspense } from 'react'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux';
import church from '@/redux/reducer';
import initState from '@/redux/store';
import App from './App'
import './index.css';

const store = createStore(church, initState as any);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Suspense>
    <HashRouter>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#C8080D',
          },
            components: {
              Menu: {
                darkItemBg: '#C8080D',
                darkItemColor: '#FFFFFF',
                darkItemSelectedBg: '#0000000F',
                darkSubMenuItemBg: '#C8080D',
                darkItemHoverBg: '#0000000F',
                darkItemHoverColor: '#ffffff',
              }
            }
        }}
        locale={zhCN}>
        <Provider store={store}>
          <App/>
        </Provider>
      </ConfigProvider>
    </HashRouter>
  </Suspense>
  );