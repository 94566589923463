import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { Navigate } from 'react-router-dom'

/* 路由懒加载 */
const Home = lazy(() => import('@/pages/home'));
const ListInfo = lazy(() => import('@/pages/list-info'));
const ContactUs = lazy(() => import('@/pages/contact-us'));
const Prayer = lazy(() => import('@/pages/prayer'));
const DetailPage = lazy(() => import('@/pages/detail-page'));
const JoinServe = lazy(() => import('@/pages/join-serve'));
const HistoryPage = lazy(() => import('@/pages/history-page'));
const HistoryDetail = lazy(() => import('@/pages/history-detail'));
const Live = lazy(() => import('@/pages/live'));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/home" />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/live',
    element: <Live/>,
  },
  {
    path: '/list-info',
    element: <ListInfo/>,
  },
  {
    path: '/join-serve',
    element: <JoinServe/>,
  },
  {
    path: '/contact-us',
    element: <ContactUs/>,
  },
  {
    path: '/prayer',
    element: <Prayer/>,
  },
  {
    path: '/detail',
    element: <DetailPage/>,
  },
  {
    path: '/history',
    element: <HistoryPage/>,
  },
  {
    path: '/history/detail',
    element: <HistoryDetail/>,
  },
];

export default routes;