import React from 'react'
import { useRoutes } from 'react-router-dom'
import ChurchLayout from '@/layout';
import routes from './router'

function App() {
  return (
    <div className="App">
      <ChurchLayout>{useRoutes(routes)}</ChurchLayout>
    </div>
  )
}

export default App;